import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CaiOpeningClaimUtility, CaiOpeningState, NewClaim } from '@rgi/digital-claims-angular';
import { UserService } from '@rgi/rx/auth';

@Injectable({
  providedIn: 'root'
})
export class CaiOpeningClaimExtUtility extends CaiOpeningClaimUtility {

  constructor(
    userService: UserService,
    @Inject('authService') authService,
    @Inject('environment') environment: any,
    @Inject('enumService') enumService: any,
    datepipe: DatePipe
  ) {
    super(userService, authService, environment, enumService, datepipe);
  }

  _transformObjectToClaim(currentClaim: CaiOpeningState, isDraft: boolean = false): Promise<NewClaim> {
    return super._transformObjectToClaim(currentClaim, isDraft).then((newClaim) => {
      return new Promise<NewClaim>(resolve => { 
        let extension = null;
        if ((<any> currentClaim.detailNewClaimInfo).cause){
          extension = { properties: [(<any> currentClaim.detailNewClaimInfo).cause] };
        } else if (currentClaim.generatedClaim && currentClaim.generatedClaim.claim 
          && currentClaim.generatedClaim.claim.extension) {
          extension = currentClaim.generatedClaim.claim.extension;
        }
        newClaim.newClaimSaveInput.claim.extension = extension;
        resolve(newClaim);
      });
    });
  }

  _transformObjectToClaimJs(state: CaiOpeningState): Promise<any> {
    return super._transformObjectToClaimJs(state).then((newClaim) => {
      return new Promise<any>(resolve => {
        let extension = { properties: [(<any> state.detailNewClaimInfo).cause] };
        newClaim.extension = extension;
        resolve(newClaim);
      });
    });
  }
}
