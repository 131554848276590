import {PortalConfig, RGI_RX_PORTAL_CONFIG} from '@rgi/rx/portal';
import {Provider} from '@angular/core';
import {environment} from '../environments/environment';
import {RGI_RX_QA_CONFIG, RgiRxQAConfig} from '@rgi/rx';
import {RGI_RX_DATETIME_CONFIG, RGI_RX_DEFAULT_DATETIME_CONFIG} from '@rgi/rx/ui';
import {RgiRxDateTimeConfig} from '@rgi/rx/ui/src/datepicker/adapter/locale.providers';

const portalConfig: PortalConfig = {
    module: 'app',
    ui: {
        enableGlobalHttpLoadingIndicator: true
    },
    i18n: {
        mergeCatalog: false
    }
};

const qualityAssuranceConfig: RgiRxQAConfig = {
    debug: false,
    enabled: true
};


/**
 * @description an array of providers that are used to configure the application and specify feature modules flags.
 */
export const APP_CONFIG_PROVIDERS: Provider[] = [
    {provide: RGI_RX_PORTAL_CONFIG, useValue: portalConfig},
    {
        provide: RGI_RX_DATETIME_CONFIG,
        useValue: {
            locale: 'LOCALE_ID'
        } as RgiRxDateTimeConfig // fixme this should not be LOCALE_ID because doesn't follow the application language
    },
    {provide: 'environment', useValue: environment},
    {provide: 'RGI_POSTSALES_CANCELLATION_OVERRIDE', useValue: false}, //todo make this a config token from Postsales?
    {provide: RGI_RX_QA_CONFIG, useValue: qualityAssuranceConfig},
    {provide: 'showEntryPointDateOfBirth', useValue: false}, // todo what is the owner of this? life?,
    {provide: 'RGI_ANAG_PORTAL_AJS_FLOW', useValue: false}, // todo make this a config token from Anag?
    {provide: 'RGI_ANAG_PORTAL_AJS_CARD_OVERRIDE', useValue: true} // todo make this a config token from Anag?
];

