import { PostSalesOperation, PNC_POSTSALES_OPERATIONS_TYPES, PNC_POSTSALES_OPERATIONS_CODE, RGI_PNC_POSTSALES_STEPS, PncPsalesRequiredDataStateManagerDates, PncDomainService, API_PREFIX_PORTFOLIO, PncPsalesTechAccountingStateManager, PncPsalesSummaryStateManagerVariations, PncPsalesConfirmationStateManager } from "@rgi/pnc-postsales";
import { PncPsalesSupplementaryTechAccStateManager } from "@rgi/pnc-postsales/lib/state-managers/technical-accounting/supplementary-tech-acc-state-manager.service";
import { PncPsalesSupplementaryTechAccStateManagerExt } from "../state-managers/supplementary-tech-acc-state-manager-ext.service";

// Appendice con Titolo Contabile - Technical Accounting Appendix
export const PNC_PSALES_VD0059: PostSalesOperation = {
  type: PNC_POSTSALES_OPERATIONS_TYPES.ASSETS_DATA_AMENDMENTS,
  operationCode: PNC_POSTSALES_OPERATIONS_CODE.TECHNICAL_ACCOUNTING_APPENDIX,
  sessionTitle: '_PCPSALES_._SESSION_TITLE_._TECHNICAL_ACCOUNTING_APPENDIX_',
  steps: [
    {
      step: RGI_PNC_POSTSALES_STEPS.REQUIRED_DATA,
      manager: PncPsalesRequiredDataStateManagerDates,
      deps: [PncDomainService],
      stepLabel: '_PCPSALES_._STEPS_._VARIATION_DATES_',
      context: {apiPrefix: API_PREFIX_PORTFOLIO}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.TECHNICAL_ACCOUNTING,
      manager: PncPsalesTechAccountingStateManager,
      deps: [PncDomainService],
      stepLabel: '_PCPSALES_._STEPS_._TECHNICAL_ACCOUNTING_',
      context: {apiPrefix: API_PREFIX_PORTFOLIO}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.SUPPLEMENTARY_TECH_ACC,
      manager: PncPsalesSupplementaryTechAccStateManagerExt,
      deps: [PncDomainService],
      stepLabel: '_PCPSALES_._STEPS_._SUPPLEMENTARY_TECH_ACC_',
      context: {apiPrefix: API_PREFIX_PORTFOLIO}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.SUMMARY,
      manager: PncPsalesSummaryStateManagerVariations,
      deps: [PncDomainService],
      stepLabel: '_PCPSALES_._STEPS_._SUMMARY_',
      context: {apiPrefix: API_PREFIX_PORTFOLIO}
    },
    {
      step: RGI_PNC_POSTSALES_STEPS.CONFIRMATION,
      manager: PncPsalesConfirmationStateManager,
      deps: [],
      stepLabel: ''
    }
  ]
};
