import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PNC_POSTSALES_OPERATIONS, RgiPncPostsalesModule, RgiPncPsalesFlowsModule } from '@rgi/pnc-postsales';
import { RgiMotorPostsalesModule } from '@rgi/motor-postsales';
import { PNC_PSALES_CLACRT } from './flows/pnc-postsales-CLACRT.routes';
import { PNC_PSALES_VD0059 } from './flows/pnc-postsales-VD0059.routes';
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RgiPncPsalesFlowsModule,
        RgiMotorPostsalesModule,
        RgiPncPostsalesModule.withOperation([PNC_PSALES_CLACRT,PNC_PSALES_VD0059])
    ],
    /*providers: [
        { provide: PNC_POSTSALES_OPERATIONS, useValue: PNC_PSALES_CLACRT, multi: true }
    ]*/
})
export class PncPsalesFlowsModule {
}
