import { Component, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AmendmentService, AssetComponent, AssetService, ClauseService, CommonService, ErrorMessagesService, InsuranceBackgroundService, Message, PartiesService, ProposalService, RoutingService, StateService, VariablesService } from "@rgi/motor-issue-card";
@Component({
    selector: 'mic-asset-ext',
    templateUrl: './asset.component.html',
    styleUrls: ['./asset.component.scss']
})
export class AssetExtComponent extends AssetComponent {
    constructor(
        protected translate: TranslateService,
        protected commonService: CommonService,
        protected proposalService: ProposalService,
        protected stateService: StateService,
        protected insuranceBackgroundService: InsuranceBackgroundService,
        protected clauseService: ClauseService,
        protected routingService: RoutingService,
        protected variablesService: VariablesService,
        protected assetService: AssetService,
        protected errorMessagesService: ErrorMessagesService,
        protected partiesService: PartiesService,
        protected amendmentService: AmendmentService) {
            super(translate,commonService,proposalService,stateService,insuranceBackgroundService,clauseService,routingService,variablesService,assetService,errorMessagesService,
                partiesService, amendmentService
            );
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

onPartyVariablesCompleted(event: boolean) {
        if (event === true) {

          this.partyAreaVariablesCompletenessErrors = false;

          this.readWarning();
          // remove erroe completess from contract
          this.removeCompletenessErrors('VARIABLES_AREA_PARTY_NOT_COMPLETE');

          if (this.assetAreaVariablesCompletenessErrors
            || this.variablesCompletenessErrors) {
            return;
          }

          this.onVariablesCompleted(event);


          if ((this.isSubstitution && this.assetDataCompleted
            && this.showInsuranceBackground) || (this.assetDataCompleted
              && this.showInsuranceBackground
              && !this.proposalService.existInsuranceStatusCompletenessErrors())) {
            this.showATR = true;

            if (!this.proposalService.isInsuranceStatusNoLongerValid()) {
              this.variablesService.getVariables().subscribe(
                array => {
                  array.forEach(element => {
                    if (element.code === '2DICAG') {
                      if (element.compulsory) {
                        this.additionalDeclarationIsMandatory = true;
                      } else {
                        this.additionalDeclarationIsMandatory = false;
                      }
                      if (!element.value && this.additionalDeclarationIsMandatory) {
                        this.onInsuranceBackgroundCompleted(false);
                      } else {
                        this.onInsuranceBackgroundCompleted(true);
                      }
                    }
                  });
                }
              );
            }
          }
        }
      }

      onAssetDataCompleted(event: boolean) {
        super.onAssetDataCompleted(event);

        if (event === true) {
          let areAssetVariablesCompleted = true;
          let arePartyVariablesCompleted = true;

          if (this.proposalService.existTechnicalDataCompletenessErrors()
            || this.proposalService.existPartyAreaVariablesCompletenessErrors()) {
            arePartyVariablesCompleted = false;
          }

          if (this.proposalService.existVehiclevariablesCompletenessErrors()
            || this.proposalService.existAssetAreaVariablesCompletenessErrors()
            || this.proposalService.existVehicleDataAreaVariablesCompletenessErrors()) {
            areAssetVariablesCompleted = false;
          }

          if (arePartyVariablesCompleted && areAssetVariablesCompleted) {
            this.showInsuranceBackground = true;
          }
        }
      }
}