import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { NotifierService } from '@rgi/portal-ng-core';
import { DocumentsService } from '@rgi/portal-ng-common';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import {
    AuthorizationsDetailComponent,
    AuthorizationsRoutingService,
    AuthorizationsStatesService,
    AuthorizationsSearchService,
    CommonService,
    AuthorizationCardStateManagerDetail,
    Authorization, AuthorizationsActions, AuthorizationCardReducerService
} from '@rgi/authorizations-card';
import { ModalService } from '@rgi/rx/ui';
import { ActiveRoute } from '@rgi/rx/router';
import { RgiRxUserService } from '@rgi/rx/auth';

@Component({
    templateUrl: './authorizations-detail-es-ext.component.html',
    providers: [AuthorizationsStatesService]
})
export class AuthorizationsDetailEsExtComponent extends AuthorizationsDetailComponent implements OnInit, OnDestroy {

    @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
    @Output() eventPropagation = new EventEmitter<any>();

    authorizationList: any[];
    warningMessages: string[] = [];

    constructor(
      protected stateService: AuthorizationsStatesService,
      protected manager: AuthorizationCardStateManagerDetail,
      public activeRoute: ActiveRoute,
      protected authorizationsSearchService: AuthorizationsSearchService,
      protected routingService: AuthorizationsRoutingService,
      protected commonService: CommonService,
      protected documentsService: DocumentsService,
      protected translate: RgiRxTranslationService,
      protected notifierService: NotifierService,
      protected modalService: ModalService,
      protected userService: RgiRxUserService,
      private reducer: AuthorizationCardReducerService,
      @Inject('showDocuments') showDocumentsEnabled?: boolean) {
        super(
          stateService,
          manager,
          activeRoute,
          authorizationsSearchService,
          routingService,
          commonService,
          documentsService,
          translate,
          notifierService,
          modalService,
          userService,
          showDocumentsEnabled
        );
    }

    ngOnInit() {
      super.ngOnInit();
    }


    ngOnDestroy() {
        super.ngOnDestroy();
    }

    back(event?: {changedAuthorizationStatus: any, authorization: Authorization}) {
        const authFilter = this.authorizationsSearchService.getAuthorizationFilter();
        if (!authFilter) {
            // when is a deeplink there isn't authFilter
            if (event?.changedAuthorizationStatus) {
                this.refreshComponent(event);
            } else {
                this.commonService.removeContract(this.contractId).subscribe();
                this.removeSession();
            }
        } else {
          super.back(event);
        }
    }

    removeSession() {
        this.stateService.resetState(this.activeRoute.id);
        this.reducer.removeSession(this.activeRoute.id);
    }
}

