import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RgiAnagPortalModule} from '@rgi/anag/portal';
import {
    ANAG_MODAL_COMPONENTS,
    ANAG_ROUTES,
    ANAG_ROUTE_HOME,
    ANAG_ROUTE_MODAL_HOME,
    AnagConfigService,
    AnagExtensionsService,
    AnagHomeFieldConfigurationService,
    AnagResourceService,
    AnagStatelessOpResultService,
    AnagStatelessService,
    ROUTES_MODAL_HOME,
    RgiAnagModule,
    AnagStorageService
} from '@rgi/anag';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {TRANSLATIONS_ANAG} from 'src/rxTranslations/i18n';
import {AnagResourceServiceExt} from './anag-resources/anag-resource.service-ext';
import {AnagStatelessExtService} from "./anag-services/anag-stateless.service-ext";
import {IdentificationDataComponentExt} from './components/identification-data/identification-data.component-ext';
import {RgiRxAutoCompleteModule,RgiRxDatePickerModule,RgiRxDragDropModule,RgiRxExpansionModule,RgiRxFormElementsModule,RgiRxLoadingModule,RgiRxModalModule,RgiRxNativeDateTimeModule,RgiRxPanelModule,RgiRxSnackbarModule,RgiRxSwitchModule,RgiRxTabModule,RgiRxTableModule,RgiRxTooltipModule} from '@rgi/rx/ui';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ROUTES, RgiRxRoutingModule} from '@rgi/rx/router';
import {AnagPrivacyComponentEsExt} from "./components/privacy/anag-privacy.component-es-ext";
import {AnagHomeFieldConfigurationServiceExt} from "./anag-services/anag-home-field-configuration.service-ext";
import {AnagHomeComponentExt} from "./components/home/anag-home.component-ext";
import {AnagStateManagerHomeExt} from "./components/home/anag-state-manager-home-ext";
import { AnagConfigServiceExt } from './anag-resources/anag-config.service-ext';
import { AnagExtensionsServiceExt } from './anag-resources/anag-extensions.service-ext';
import { AnagModalComponentExt } from './components/modal/anag-modal.component-ext';
import { ANAG_ROUTE_MODAL_EXT_HOME, ROUTES_MODAL_HOME_EXT } from './anag-constants/anag-constants-ext';
import { AnagStatelessOpResultServiceExt } from './anag-services/anag-stateless-op-result.service-ext';
import { ANAG_CARD_EXT } from './rgi-anag.card.configurations';
import { RgiRxPortalModule } from '@rgi/rx/portal';
import {AnagStorageServiceExt} from "./anag-resources/anag-storage.service-ext";

function patchAnagHomeProviders() {
    return [
        {
            ...ANAG_ROUTE_HOME.providers[0],
            useClass: AnagStateManagerHomeExt
        },
        ...ANAG_ROUTE_HOME.providers.slice(1)
    ];
}

export const ANAG_ROUTES_EXT = ANAG_ROUTES.map(c => {
    if (c.route === ROUTES_MODAL_HOME) {
      c = ANAG_ROUTE_MODAL_EXT_HOME;
    }
    return c;
  });

@NgModule({
    declarations: [
        IdentificationDataComponentExt,
        AnagPrivacyComponentEsExt,
        AnagHomeComponentExt,
        AnagModalComponentExt
    ],
    imports: [
        CommonModule,
        RgiAnagModule,
        RgiAnagPortalModule,
        RgiRxI18nModule.forRoot(TRANSLATIONS_ANAG),
        ReactiveFormsModule,
        RgiRxRoutingModule,
        RgiRxModalModule,
        RgiRxTableModule,
        RgiRxLoadingModule,
        RgiRxAutoCompleteModule,
        RgiRxFormElementsModule,
        RgiRxTabModule,
        RgiRxDragDropModule,
        FormsModule,
        RgiRxPanelModule,
        RgiRxSnackbarModule,
        RgiRxExpansionModule,
        RgiRxDatePickerModule,
        RgiRxNativeDateTimeModule,
        BrowserAnimationsModule,
        RgiRxTooltipModule,
        RgiRxSwitchModule,
        RgiRxRoutingModule.forRoot(ANAG_ROUTES_EXT),
        RgiRxRoutingModule.forRoot([
            {...ANAG_ROUTE_HOME, component: AnagHomeComponentExt, providers: patchAnagHomeProviders()}
        ]),
        RgiRxPortalModule.forRoot(ANAG_CARD_EXT)
    ],
    providers: [
        {provide: AnagStatelessService, useClass: AnagStatelessExtService},
        {provide: AnagResourceService, useClass: AnagResourceServiceExt},
        {provide: AnagConfigService, useClass: AnagConfigServiceExt},
        {provide: AnagHomeFieldConfigurationService, useClass: AnagHomeFieldConfigurationServiceExt},
        {provide: AnagExtensionsService, useClass: AnagExtensionsServiceExt},
        {provide: AnagStatelessOpResultService, useClass: AnagStatelessOpResultServiceExt},
        {provide: 'RGI_ANAG_PORTAL_AJS_MODAL_OVERRIDE', useValue: true}, // serve per aprire modale ricerca anagrafica A8
        {provide: 'RGI_ANAG_PORTAL_AJS_CARD_OVERRIDE', useValue: true}, // sostituisce la card di anagrafica con quella in A8
        {provide: AnagStorageService, useClass: AnagStorageServiceExt},
        {
            provide: ANAG_MODAL_COMPONENTS,
            useValue: [
                {key: 'IdentificationDataComponent', component: IdentificationDataComponentExt},
                {key: 'AnagPrivacyComponent', component: AnagPrivacyComponentEsExt},
                { key: 'AnagModalComponent', component: AnagModalComponentExt }
            ],
            multi: true
        }
    ]
})
export class AnagModule {
}