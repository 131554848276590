import {Injectable} from "@angular/core";
import {
    AnagEntityIta,
    AnagResourceService,
    AnagStorageService,
    AnagUmaAddressService,
    MAIN_CONTACT_ENUM
} from "@rgi/anag";

@Injectable()
export class AnagStorageServiceExt extends AnagStorageService {

    constructor(
        public anagResources: AnagResourceService,
        public addressService: AnagUmaAddressService
    ) {
        super(anagResources, addressService);
    }

    getEnumsByCode(code: string): Array<AnagEntityIta> {
        let extEnums = super.getEnumsByCode(code);
        if (code === MAIN_CONTACT_ENUM) {
            return extEnums.filter(elem=> elem.codice === '1' || elem.codice === '2');
        } else {
            return extEnums;
        }
    }

}