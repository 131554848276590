import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ClaimOpeningDetailsService, ExternalPolicy } from '@rgi/digital-claims-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimOpeningDetailsExtService extends ClaimOpeningDetailsService{

  private _baseApiUrl: string;
  private _urlServiceSavePotentialClaim = '/claims/potentialclaimsave';
  constructor(
    httpClient: HttpClient,
    @Inject('environment') environment: any,
    @Inject('coreResult') private coreResult: any,
    @Inject('sessionService') private sessionService: any
  ) {
    super(httpClient, environment);
    this._baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  savePotentialClaim(input: string, claimNumber?: string, idTypePtf?: string, extPol?: ExternalPolicy, idPotentialClaimArg?: string) {
    const cause = this.coreResult.getResult(this.getCurrentSession().id, 'causeNoRcaFlow');
    let obs: Observable<any>;
    if (claimNumber) {
      obs = this.httpClient.post(this._baseApiUrl + this._urlServiceSavePotentialClaim,
        {
          uuid: input, potentialClaimNumber: claimNumber,
          typePtf: idTypePtf, externaPolicy: extPol, idPotentialClaim: idPotentialClaimArg, 
          extension: { properties: [ {chiave: 'cause', valore: cause} ] }
        });
    } else {
      obs = this.httpClient.post(this._baseApiUrl + this._urlServiceSavePotentialClaim,
        { uuid: input, typePtf: idTypePtf, externaPolicy: extPol, idPotentialClaim: idPotentialClaimArg, extension: { properties: [ cause ] }});
    }
    return obs;
  }
  getCurrentSession() {
    return this.sessionService.list().find(el => el.isActive);
  }
}
