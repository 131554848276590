import {
  StartComponent, StateService, ProposalService, ContractCacheService,
  DataStorageService,
  MicIntegrationService,
  PartiesService,
  PassProductsService,
  StartService,
  CommonService,
  RoutingService,
  CustomModalService,
  ApiAgency
} from "@rgi/motor-issue-card";
import {
  Component,
  ComponentFactoryResolver,
  Inject
} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import { OperatorService, ModalService } from "@rgi/portal-ng-core";
import { TranslateService } from "@ngx-translate/core";

//IIAB-15250 FIXME: questo componente serve solo per le versioni < 310.2.9
@Component({
  templateUrl: './start-ext.component.html'
})
export class StartExtComponent extends StartComponent {

  constructor(
    protected operatorService: OperatorService,
    protected resolver: ComponentFactoryResolver,
    protected formBuilder: UntypedFormBuilder,
    protected passProductsService: PassProductsService,
    protected stateService: StateService,
    protected routingService: RoutingService,
    protected proposalService: ProposalService,
    protected customModalService: CustomModalService,
    protected translationService: TranslateService,
    protected contractCacheService: ContractCacheService,
    protected dataStorageService: DataStorageService,
    protected integrationService: MicIntegrationService,
    protected partiesService: PartiesService,
    protected modalService: ModalService,
    protected startService: StartService,
    protected commonService: CommonService,
    @Inject('showEntryPointDateOfBirth') showEntryPointDateOfBirth?: boolean
  ) {
    super(operatorService, resolver, formBuilder, passProductsService, stateService, routingService, proposalService,
      customModalService, translationService, contractCacheService, dataStorageService, integrationService,
      partiesService, modalService, startService, commonService, showEntryPointDateOfBirth
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.eventPropagation.emit({
      eventName: 'setTitle',
      title: this.translationService.instant('P&C Motor Flow')
    });
  }

  getOperators() {
    this.salePoint = this.operatorService.getSalePointDefault();
    if (this.salePoint?.salePointType.codice === "3" || this.salePoint?.salePointType.codice === "4") {
      /*
       * Logged on agency.
       * Show the select with nodes (if they are less than x)
       */
      this.isAgency = true;
      this.node = this.salePoint;
      this.startForm.patchValue({ node: this.salePoint.description });
      this.initializeAgencies();
    } else {
      // Logged on group node. Management with modal
      this.isAgency = false;
      this.initializeAgencies();
    }
    if (!this.salePoint && this.operatorService.getSalePointLogin()) {
      this.salePointCode = this.operatorService.getSalePointLogin().code;
    } else if (this.salePoint) {
      this.salePointCode = this.salePoint.code;
    }
  }

  protected initializeAgencies() {
    this.dataStorageService.getAvailableAgencies().subscribe((data: Array<ApiAgency>) => {
      this.availableAgencies = data;
      if (this.salePoint) {
        if (this.isAgency && (this.availableAgencies?.length > 1 && this.availableAgencies?.length < this.maxNode)) {
          this.startForm.get('node').setValue(this.salePoint.objectId);
        } else {
          this.startForm.get('node').setValue(this.salePoint);
        }
        const node = this.startService.getNode(this.availableAgencies);
        this.node = node ? node : this.salePoint;
      }

      this.eventPropagation.emit('layoutChanged');
    });
  }

    dragChangeHandlerNode(event: any) {
      this.node = event.changed;
    }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
