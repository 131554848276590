import { Component, Inject, LOCALE_ID } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimOpeningComponent, ClaimOpeningPolicySearchResultsService, ClaimOpeningService} from '@rgi/digital-claims-angular';
import { AlertService, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { RoutingService } from '@rgi/portal-ng-core';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-claim-opening',
  templateUrl: './claim-opening-ext.component.html',
  styleUrls: ['./claim-opening-ext.component.css']
})
export class ClaimOpeningExtComponent extends ClaimOpeningComponent {

  constructor(
    routingService: RoutingService,
    @Inject('searchComponent') searchComponent,
    @Inject('searchResultComponent') searchResultComponent,
    @Inject('detailsComponent') detailsComponent,
    @Inject('similarClaimsComponent') similarClaimsComponent,
    @Inject('cardPassService') cardPassService: any,
    @Inject('coreResult') coreResult: any,
    @Inject('authService') authService,
    @Inject(LOCALE_ID) locale: string,
    claimOpeningService: ClaimOpeningService,
    cardComponentService: CardComponentService,
    modalService: NgbModal,
    sessionService: SessionService,
    claimOpeningPolicySearchResultsService: ClaimOpeningPolicySearchResultsService,
    alertService: AlertService,
    rxTranslationsService: RgiRxTranslationService,
  ) {
    super(
      routingService,
      searchComponent,
      searchResultComponent,
      detailsComponent,
      similarClaimsComponent,
      cardPassService,
      coreResult,
      authService,
      locale,
      claimOpeningService,
      cardComponentService,
      modalService,
      sessionService,
      claimOpeningPolicySearchResultsService,
      alertService,
      rxTranslationsService
    );
  }

  mapPoliciesJs() {
    const policiesRes = this.coreResult.getResult(this.id, 'result').policies;
    if(policiesRes && policiesRes.length > 0) {
      this.coreResult.setResult(this.id, 'causeNoRcaFlow', policiesRes[0].extension.properties.find(r => r.chiave === 'cause'));
    }
    super.mapPoliciesJs();
  }
}
