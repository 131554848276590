import { DatePipe } from '@angular/common';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AmendmentService, CustomModalService, MicIntegrationService, Modal, PartiesComponent, PartiesService, ProposalService, QuestionnaireDnService, QuotationService } from '@rgi/motor-issue-card';
import { ModalService } from '@rgi/portal-ng-core';
import { UserAuthorizationService } from '@rgi/rx/auth';
import { Component, Inject, OnInit, Type } from '@angular/core';


@Component({
  selector: 'mic-parties-ext',
  templateUrl: './parties.component.html',
  styleUrls: ['./parties.component.scss']
})
export class PartiesExtComponent extends PartiesComponent implements OnInit {

  second_driver_enabled: boolean = true;
  proposalServices: ProposalService;

  constructor(
    protected translate: TranslateService,
    protected formBuilder: UntypedFormBuilder,
    protected partiesService: PartiesService,
    protected proposalService: ProposalService,
    protected partiesServices: PartiesService,
    protected customModalService: CustomModalService,
    protected quotationService: QuotationService,
    protected questionnaireDnService: QuestionnaireDnService,
    protected datePipe: DatePipe,
    protected integrationService: MicIntegrationService,
    protected modalService: ModalService,
    protected userAuthorizationService: UserAuthorizationService,
    @Inject('quotationComparisonModalComponent') quotationComparisonModalComponent: Type<Modal>,
    @Inject('RGI_ANAG_PORTAL_AJS_FLOW') anagPortalAjsFlow: boolean,
    protected amendmentService: AmendmentService,
  ) {
    super(translate, formBuilder, partiesService, proposalService,
      partiesService, customModalService, quotationService, questionnaireDnService, datePipe, integrationService,
      modalService, userAuthorizationService, quotationComparisonModalComponent, anagPortalAjsFlow, amendmentService
    )
    this.proposalServices = proposalService;
  }

  async ngOnInit() {
    super.ngOnInit();
    this.second_driver_enabled = this.proposalServices.isFieldEnabled('second_driver') !== null ? this.proposalServices.isFieldEnabled('second_driver') : true;
  }
}



