import {DatePipe} from '@angular/common';
import {Component, Inject, OnInit, Optional} from '@angular/core';

import {PushMessageHandlerService, RgiRxEventService} from '@rgi/rx';
import {RgiRxUserService} from '@rgi/rx/auth';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {DIALOG_DATA, ModalService} from '@rgi/rx/ui';
import {
    AnagApiParty,
    AnagConfigService,
    AnagDialogComponent,
    AnagEntityIta,
    AnagStateManagerPartyEditor,
    AnagStatelessService,
    AnagStorageService,
    IdentificationDataComponent,
    ModalDialogData
} from '@rgi/anag';
import {UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'rgi-anag-identification-data',
    templateUrl: './identification-data.component-ext.html',
    host: {
        class: 'rgi-anag-style'
    }
})
export class IdentificationDataComponentExt extends IdentificationDataComponent implements OnInit {

    constructor(
        public stateMgr: AnagStateManagerPartyEditor,
        public anagStorage: AnagStorageService,
        public eventService: RgiRxEventService,
        public anagStatelessService: AnagStatelessService,
        public configService: AnagConfigService,
        public pushMessageHandler: PushMessageHandlerService,
        public datePipe: DatePipe,
        protected translateService: RgiRxTranslationService,
        protected modalService: ModalService,
        protected userService: RgiRxUserService,
        @Optional() @Inject(DIALOG_DATA) party: AnagApiParty
    ) {
        super(stateMgr,
            anagStorage,
            eventService,
            anagStatelessService,
            configService,
            pushMessageHandler,
            datePipe,
            translateService,
            modalService,
            userService,
            party);
    }

    isBirthNameRequiredFromConfig: boolean = false;

    ngOnInit(){
        super.ngOnInit();
        this.isBirthNameRequiredFromConfig = this.anagStatelessService.deepClone(this.physicalPartyForm.get('birthName').hasValidator(Validators.required));
        this.changeRequiredOnBirthName();
        this.subscriptions.add(this.partyKeyForm.get('partyKey1').valueChanges.subscribe(name => {
            this.changeRequiredOnBirthName();
        }));
        this.subscriptions.add(this.partyKeyForm.get('partyKey2').valueChanges.subscribe(name => {
            this.changeRequiredOnBirthName();
        }));
    }

    resetChangePartyKey() {

        if (this.partyKeyForm.controls.country.value === "IT") {
            if (this.stateMgr.isPartyPhysical && this.physicalPartyForm) {
                this.resetFormFieldExt(this.partyKeyForm, 'partyKey1');
                this.resetFormFieldExt(this.partyKeyForm, 'partyKey2');
                this.resetFormFieldExt(this.partyKeyForm, 'partyKey3');
            } else if (!this.stateMgr.isPartyPhysical && this.legalPartyForm) {
                this.resetFormFieldExt(this.partyKeyForm, 'partyKey1');
                this.resetFormFieldExt(this.partyKeyForm, 'partyKey2');
                this.resetFormFieldExt(this.partyKeyForm, 'partyKey3');
            }
        }
    }

    private resetFormFieldExt(formGroup: UntypedFormGroup, key: string) {
        if (formGroup && formGroup.get(key)) {
            formGroup.get(key).setValue(undefined);
        }
    }


    handleDuplicatePartySuccess(oldParty: any, duplicatePartyCheckResp: any) {
        const messages = duplicatePartyCheckResp.result.messages;
        let idSubject = null;
        let idNode = null;
        if (messages[3] === '2' || messages[3] === '3') {
            idSubject = messages[2];
        }
        if (messages[3] === '3') {
            idNode = messages[4];
        }

        this.stateMgr.getSubjectDetail(idSubject, idNode, null).subscribe(subject => {
            if (this.checkDuplicateUserPartyData(subject)) {
                super.handleDuplicatePartySuccess(oldParty, duplicatePartyCheckResp);
            } else {
                this.translateService.translate('_ANAG_._MSG_._USER_ON_DIFFERENT_NODE_').subscribe(stringMsg => {
                    this.modalService.openComponent(AnagDialogComponent, new ModalDialogData(stringMsg, false));
                });
            }
        });


    }

    private checkDuplicateUserPartyData(subject: any) {
        if (subject && subject.subject) {
            const form = this.identificationDataForm.getRawValue();

            if (this.stateMgr.isPartyPhysical) {
                const formData = form.physicalPartyForm;

                if (!formData || !formData.name || formData.name.toUpperCase() !== subject.subject.name) {
                    return false;
                }

                if (!formData || !formData.surname || formData.surname.toUpperCase() !== subject.subject.surname) {
                    return false;
                }

                if (!formData || !formData.birthName || formData.birthName.toUpperCase() !== subject.subject.birthName) {
                    return false;
                }

                if (!formData || !formData.birthDate || this.datePipe.transform(formData.birthDate, 'yyyy-MM-dd') !== this.datePipe.transform(subject.subject.dateOfBirth, 'yyyy-MM-dd')) {
                    return false;
                }
            } else {
                const formData = form.legalPartyForm;

                if (!formData || !formData.companyName || formData.companyName.toUpperCase() !== subject.subject.denomination) {
                    return false;
                }

                if (!formData || !formData.openDate || this.datePipe.transform(formData.openDate, 'yyyy-MM-dd') !== this.datePipe.transform(subject.subject.dateOfBirth, 'yyyy-MM-dd')) {
                    return false;
                }
            }
        } else {
            return false;
        }

        return true;
    }

    changeRequiredOnBirthName() {
        if (this.partyKeyForm.get('partyKey1').value) {
            if (this.physicalPartyForm.get('birthName').enabled) {
                if (this.isBirthNameRequiredFromConfig && !this.physicalPartyForm.get('birthName').hasValidator(Validators.required)) {
                    this.physicalPartyForm.get('birthName').addValidators(Validators.required);
                    this.physicalPartyForm.get('birthName').updateValueAndValidity();
                }
            }
        } else {
            if (this.physicalPartyForm.get('birthName').enabled) {
                this.physicalPartyForm.get('birthName').setValidators(null);
                this.physicalPartyForm.get('birthName').updateValueAndValidity();
            }
        }
    }

    openIdentificationDataComponent(party) {
        const stateMgrProvider = {
          provide: AnagStateManagerPartyEditor,
          useValue: this.stateMgr
        };

        this.modalService.openComponent(IdentificationDataComponentExt,
          this.anagStatelessService.deepClone(party), [stateMgrProvider]);
    }

    setRegCountry(party) {
        if (party.regCountry) {
            this.updateFormField(this.legalPartyForm.get('regCountry'), party.regCountry);
        } else if (!party.regCountry && !this.stateMgr.isPartyPhysical) {
           this.resetFormFieldExt(this.legalPartyForm, 'regCountry');
        }
    }


    updateIdentificationData(){
         const checkValid = this.inputParty.personType.codice == '1' ? this.physicalPartyForm.valid : this.legalPartyForm.valid;
         if (checkValid) {
            const filter = this.createRequestCheckPartyKey();
            this.stateMgr.checkPartyKey(filter).subscribe(resp => {
                if (resp.outcome !== null) {
                    this.notifyFormValidationErrorMsg(resp.outcome);
                    this.isDisabled = true;
                } else {
                    this.pushMessageHandler.clearTag('errorMsg-tag');
                    this.changedPartyKey = true;
                    this.isDisabled = false;
                    super.updateIdentificationData();
                }
            });
        } else {
            this.notifyFormValidationErrorMsg('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_');
        }
    }

}

