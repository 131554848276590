import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {RgiRxPortalModule} from "@rgi/rx/portal";
import {AUTHORIZATIONS_CARD_EXT} from "./authorizations-card.configurations";
import {AuthorizationsDetailEsExtComponent} from "./authorizations-detail/authorizations-detail-es-ext.component";
import {AUTHORIZATION_CARD_ROUTES, AuthorizationsCardModule} from "@rgi/authorizations-card";
import {RgiRxRoutingModule} from "@rgi/rx/router";
import {RgiRxI18nModule} from "@rgi/rx/i18n";
import {RgiRxExpansionModule, RgiRxFormElementsModule} from "@rgi/rx/ui";
import {PortalNgCoreModule} from "@rgi/portal-ng-core";
import {PortalNgCommonModule} from "@rgi/portal-ng-common";

const AUTH_ROUTES_EXTENDED = AUTHORIZATION_CARD_ROUTES.map(r => {
    if (r.route === 'authorizations') {
        r.children?.filter(route => route.route === 'detail').map(route => {
            route.component = AuthorizationsDetailEsExtComponent;
            return route;
        })
    }
    return r;
});

@NgModule({
    declarations: [
        AuthorizationsDetailEsExtComponent
    ],
    imports: [
        AuthorizationsCardModule,
        CommonModule,
        PortalNgCoreModule,
        PortalNgCommonModule,
        RgiRxI18nModule,
        RgiRxExpansionModule,
        RgiRxFormElementsModule,
        RgiRxPortalModule.forRoot(AUTHORIZATIONS_CARD_EXT),
        RgiRxRoutingModule.forRoot(AUTH_ROUTES_EXTENDED)
    ],
    providers: [
        DatePipe,
        {provide: 'authorizationsDetailComponent', useValue: AuthorizationsDetailEsExtComponent}
    ]
})
export class AuthorizationsModule {
}
