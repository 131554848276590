import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimOpeningDetailsService, ClaimOpeningModule } from '@rgi/digital-claims-angular';
import { ClaimOpeningExtComponent } from './claim-opening-ext.component';
import { RgiRxExpansionModule, RgiRxFormElementsModule, RgiRxModalModule, RgiRxPanelModule, RgiRxSnackbarModule, RgiRxTableModule, RgiRxTabModule, RgiRxTooltipModule } from '@rgi/rx/ui';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardService, DigitalClaimsCommonAngularModule } from '@rgi/digital-claims-common-angular';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RgiAnagModule } from '@rgi/anag';
import { ClaimOpeningDetailsExtService } from './components/claim-opening-details/claim-opening-details-ext.service';



@NgModule({
  declarations: [
    ClaimOpeningExtComponent
  ],
  imports: [
    CommonModule,
    ClaimOpeningModule,
    RgiRxSnackbarModule,
    PortalNgCoreModule,
    FormsModule,
    ReactiveFormsModule,
    DigitalClaimsCommonAngularModule,
    RgiRxI18nModule,
    RgiRxTabModule,
    RgiRxExpansionModule,
    RgiRxTableModule,
    RgiRxFormElementsModule,
    RgiRxTooltipModule,
    RgiRxPanelModule,
    RgiRxModalModule
  ],
  providers: [
    { provide: ClaimOpeningDetailsService, useClass: ClaimOpeningDetailsExtService },
  ]
})
export class ClaimOpeningExtModule {
  constructor(cardService: CardService) {

    const title = '_NEW_FNOL';
    const moduleName = 'claim-opening';
    const communication = {
      bodywork: 'bodywork',
      bodyworkfrompass: 'bodyworkFromPass',
      driver: 'driver',
      dsess: 'dsess',
      third: 'third',
      newSubject: 'newSubject'
    };
    const routes = [{
      path: 'newCarBodywork', route: {
        template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
        controller: 'CardPassCtrl',
        label: 'New Garage'
      }
    }];

    const cardOpeningModule = cardService.registerCard(
      moduleName,
      title,
      ClaimOpeningExtComponent,
      'ClaimsOpeningCommunicationCtrl',
      communication,
      routes,
      null,
      false,
      null
    );

    cardService.addRouteToParallelRoutingProvider(
      cardOpeningModule,
      'anagFinder',
      'result',
      {
        template: 'anag/cardfinder/anag-card-finder-result.tpl.html',
        controller: 'AnagCardFinderControllerComm_result',
        label: 'Edit - Anagrafica Finder'
      }
    );

  }
}
