// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {RgiAuthOpenIdConfig, RgiAuthOpenIdTokenRefreshService} from "@rgi/iiab-gel-library";
import {RgiRxAuthConfig} from "@rgi/rx/auth";
import {DeepLinkLegacyConfig} from "../app/deeplink/model/DeepLinkLegacyConfig";

export const environment = {
    production: false,
    api: {
        portal: {
            host: '',
            path: '/api/rest'
        },
        gateway: {
            path: '/v2'
            // path: ''
        },
        custom_gel: {
            path: '/gel'
        }
    }
};

export const openIdConfig: RgiAuthOpenIdConfig = {
    oidcFlow: false,
    url: 'http://plft-keycloak-iiab.ad.rgigroup.com:10100/realms/iiab_sp/protocol/openid-connect',
    flow: "code",
    wellKnow: {
        token_endpoint: `/token`,
        authorization_endpoint: '/auth',
        userinfo_endpoint: '/userinfo',
        revocation_endpoint: '/revoke',
        end_session_endpoint: '/logout',
        introspection_endpoint: '/introspect'
    },
    clientId: 'im-human',
    clientSecret: 'paTkCS4lOWVork2W6fz6B4gPseYDaYJa',
    scope: 'offline_access openid'
};

export const deeplinkLegacyConfig: DeepLinkLegacyConfig = {
    host: 'http://deeplink.legacy.com',
    policy: {function: '/emi_genComunFormWeb/inicio.do?sessionState=new&oper=061&cia=G'},
    proposal: {function: '/webapps/pre-contractual-management/?'},
    authorization: {function: '/emi_genComunFormWeb/inicio.do?sessionState=atomic&sigView=si&oper=048&jumpToStep=controles'},
    transaction: {function: '/sir_mediadorFormWeb/procesoGestionRecibosInt.do?sessionState=new'}
};

export const rgiRxAuthConfig: RgiRxAuthConfig = {
    preMatchingRefreshTokenProvider: RgiAuthOpenIdTokenRefreshService
};

export const environmentPropro = {
    production: false,
    apiUrl: 'http://pltf-passproproportal-int.ad.rgigroup.com:8580/api/rest'
};

export const environmentKarma = {
    apiUrl: 'http://runner-uma02.ad.rgigroup.com:8090/karma-rm/rest/karma',
    credentials: {
        login: 'ADMINKAPIA',
        password: 'PASSWORD'
    }
};

export const environmentK4u = {
    Url: 'http://runner-uma02.ad.rgigroup.com:4050/b2b'
};

export const restApiConf = {
    endpoint: '/api/rest',
    endpointPI: '/api/rest/v2'
};

export const restApiConfAnag = {
    endpoint: '/api/rest'
};

// @ts-ignore
if (typeof applyOverrides !== 'undefined') {
    // @ts-ignore
    applyOverrides(openIdConfig,
        deeplinkLegacyConfig);
}


declare var angular: angular.IAngularStatic;
angular.module('app').value('API_CONF', {
    base_url: '/rest',
    server: '/api',
    serverPassInsurance: 'http://pltf-umapass-int.ad.rgigroup.com',
    linkPassInsurance: 'http://pltf-umapass-int.ad.rgigroup.com/uma/multisession/ServletLogin',
    serverBundle: 'https://elastik-sale-shared-dev.aws.rgigroup.com/'
});

angular.module('app').value('API_CONF_OPEN_CONNECT', {
    openIdConfig
});


angular.module('app').config(['$sceDelegateProvider', function ($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        'http://pltf-umapassportal-int.ad.rgigroup.com/api/rest/**',
        'https://web1.unimaticaspa.it/**',
        'https://query.yahooapis.com/**',
        'https://elastik-sale-shared-dev.aws.rgigroup.com/**',
        'https://elastik-sale-shared-dev.aws.rgigroup.com/**',
        'http://localhost:4200'
    ]);
}]);

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
