import {Injectable} from '@angular/core';
import { ModalService } from '@rgi/rx/ui';
import { OutboundDocumentsModalComponent, OutboundDocumentsReference } from '@rgi/shared-components';

@Injectable({
  providedIn: 'root'
})
export class PncPostsalesJsToAngularExtService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(protected modalService: ModalService) {}

  openModalOutboundDocuments(policyNumber: string, proposalNumber: string, movementId: string, operationDescription: string) {
      //if (this.isUserFnAllowed('ODMMFN')) {
        const docRef = new OutboundDocumentsReference(policyNumber, proposalNumber, movementId, operationDescription);
        const modal = this.modalService.openComponent(OutboundDocumentsModalComponent, docRef);
        modal.modal.enableClickBackground = false;
      //} else {
      //  console.error('User does not have permission to open Outbound Documents Modal'); //TODO understand if we show the print button
      //}
  }

}
