import {Injectable} from '@angular/core';
import {AnagPartyKey, AnagStatelessService} from '@rgi/anag';
import {AnagPartyKeyExt} from "../anag-domain/anag-domain";

@Injectable()
export class AnagStatelessExtService extends AnagStatelessService {

    extendsAnagPartyKey(physicalPartyForm, legalPartyForm, partyKeyConf: AnagPartyKey) {
        const completesurname = physicalPartyForm.get('surname').value.concat(' ',physicalPartyForm.get('birthName').value);
        if (physicalPartyForm && legalPartyForm && partyKeyConf) {
            partyKeyConf.extensionSubject = JSON.stringify(new AnagPartyKeyExt(physicalPartyForm.get('name').value,
            completesurname, legalPartyForm.get('companyName').value));
        }
    }

    formatDateToStringPeriod(date: Date): string {
        return this.datePipe.transform(date, 'MM/yyyy');
    }
}
