import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    MIC_HTTP_INTERCEPTOR,
    MotorIssueCardModule,
    MotorIssueCardPortalIntegrationModule,
    StartService
} from '@rgi/motor-issue-card';
import {AuthorizationsCardModule} from '@rgi/authorizations-card';
import {TokenAuthorizationInterceptorService} from '@rgi/rx/auth';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {TranslateModule} from '@ngx-translate/core';
import {ProposalExtComponent} from './proposal/proposal.component';
import {PortalNgCommonModule} from '@rgi/portal-ng-common';
import {PolicySummaryExtComponent} from './policy-summary/policy-summary.component';
import {IiabPortalExtModule, ProposalOtpModule} from "@rgi/iiab-portal-ext";
import {IssueCardStartService} from './extension/services/issue-card-start.service';
import {QuotationExtComponent} from './quotation/quotation.component';
import {MandatoryDataEsExtComponent} from "./mandatory-data/mandatory-data-es-ext.component";
import { VehicleDataExtComponent } from './vehicle-data/vehicle-data.component';
import { AssetExtComponent } from './asset/asset.component';
import { StartExtComponent } from './start-card/start-ext.component';
import { RgiRxDatePickerModule, RgiRxDragDropModule, RgiRxFormElementsModule } from '@rgi/rx/ui';
import { PartiesExtComponent } from './proposal/parties/parties.component';
import {RgiSharedComponentsModule} from '@rgi/shared-components';

@NgModule({
  declarations: [
    AssetExtComponent,
      ProposalExtComponent,
      PolicySummaryExtComponent,
      QuotationExtComponent,
      MandatoryDataEsExtComponent,
      VehicleDataExtComponent,
      StartExtComponent,
      PartiesExtComponent],
    imports: [
        CommonModule,
        MotorIssueCardPortalIntegrationModule,
        AuthorizationsCardModule,
        FormsModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        TranslateModule,
        MotorIssueCardModule,
        PortalNgCommonModule,
        IiabPortalExtModule,
        ProposalOtpModule,
        RgiRxDragDropModule,
        RgiSharedComponentsModule,
        RgiRxDatePickerModule,
        RgiRxFormElementsModule
    ],
  providers: [

    { provide: 'saveQuoteButtonEnabled', useValue: false },
    { provide: 'proposalComponent', useValue: ProposalExtComponent},
    { provide: 'policySummaryComponent', useValue: PolicySummaryExtComponent},
    { provide: 'quotationComponent', useValue: QuotationExtComponent},
    { provide: StartService, useClass: IssueCardStartService },
    {
      provide: MIC_HTTP_INTERCEPTOR,
      multi: true,
      useClass: TokenAuthorizationInterceptorService
    },
    { provide: 'mandatoryDataComponent', useValue: MandatoryDataEsExtComponent },
    { provide: 'assetComponent', useValue: AssetExtComponent },
    { provide: 'startComponent', useValue: StartExtComponent },
    { provide: 'partiesComponent', useValue: PartiesExtComponent }
  ],
  entryComponents: [ProposalExtComponent, PolicySummaryExtComponent, QuotationExtComponent, AssetExtComponent]

})
export class MotorModule {}
