import { Component, Inject, OnInit, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Action, ChangeVehicleModalComponent, ContractClauseService, CustomModalService, ErrorMessagesService, InsuranceBackgroundService, LiensService, MagazinesService, Modal, ParameterService, ProposalService, QuotationComponent, QuotationService, QuoteService, RiskCertificateService, RoutingService, State, StateService, VariablesService } from "@rgi/motor-issue-card";
import { ModalService } from "@rgi/portal-ng-core";
import { RgiRxUserAuthorizationService } from "@rgi/rx/auth";
import { ClaimsHistoryModalComponent } from "src/app/ext/claims/claims-history-modal/claims-history-modal.component";

@Component({
    selector: 'mic-quotation-ext',
    templateUrl: './quotation.component.html',
    styleUrls: ['./quotation.component.scss']
  })
  export class QuotationExtComponent extends QuotationComponent {
    showClaimsHistoryButton: boolean = false;

    @ViewChild('modalClaimsHistoryOverlay', {
        read: ViewContainerRef,
        static: true
    }) claimsHistoryModalViewContainerRef: ViewContainerRef;
    

    constructor(
        protected translate: TranslateService,
        protected variablesService: VariablesService,
        protected magazineService: MagazinesService,
        protected formBuilder: UntypedFormBuilder,
        protected stateService: StateService,
        protected routingService: RoutingService,
        protected proposalService: ProposalService,
        protected quotationService: QuotationService,
        protected customModalService: CustomModalService,
        protected modalService: ModalService,
        protected errorMessagesService: ErrorMessagesService,
        protected insuranceStatusService: InsuranceBackgroundService,
        protected riskCertificateService: RiskCertificateService,
        protected parameterService: ParameterService,
        protected liensService: LiensService,
        protected clauseService: ContractClauseService,
        protected quoteService: QuoteService,
        protected userAuthorizationService: RgiRxUserAuthorizationService,
        @Inject('showInfocarCodeInQuotationPage') showInfocarCodeInQuotationPage?: boolean,
        @Inject('viewUnitsBox') viewUnitsBox?: boolean,
        @Inject('saveQuoteButtonEnabled') saveQuoteButtonEnabled?: boolean,
        @Inject('quotationDetailsModalComponent') quotationDetailsModal?: Type<Modal>,
        @Inject('packageVariablesModalComponent') packageVariablesModalComponent?: Type<Modal>,
        @Inject('discountsModalComponent') discountsModal?: Type<Modal>
      ) {
        super(translate, variablesService, magazineService, formBuilder, stateService, routingService, proposalService,
            quotationService, customModalService, modalService, errorMessagesService, insuranceStatusService, riskCertificateService,
            parameterService, liensService, clauseService, quoteService, showInfocarCodeInQuotationPage, viewUnitsBox, saveQuoteButtonEnabled,
            quotationDetailsModal, packageVariablesModalComponent, discountsModal);
      }
    
      async ngOnInit() {
        super.ngOnInit();
        this.showClaimsHistoryButton = this.userAuthorizationService.isAuthorizedFor('claims.history.show');
      }
    
      ngOnDestroy(): void {
        super.ngOnDestroy();
      }
      
      openClaimsHistory() {
        const modal = this.customModalService.openModal(this.claimsHistoryModalViewContainerRef, ClaimsHistoryModalComponent);
        modal.instance.contractId = this.proposalService.getApiContract() ? this.proposalService.getApiContract().id : null;
      }

    thisIsNotMyVehicle() {
      this.stateService.nextState(Action.NOT_MY_VEHICLE_BUTTON_PRESSED);
      this.openProductAssetModal();
    }

    protected openProductAssetModal() {
      const modal = this.customModalService.openModal(this.productAssetModalViewContainerRef, ChangeVehicleModalComponent,
        this.eventPropagation, () => {
          if (modal.instance.isChangePlate && this.stateService.isNotValidContract() === true) {
              this.stateService.setCurrentState(State.QUOTATION_STANDARD_PLATE);
              this.stateService.nextState(Action.EDIT_ASSET_DATA_BUTTON_PRESSED);
          } else {
            if (modal.instance.bRecalculateQuotation) {
              this.assetStaticData.length = 0;
              this.getPremium(true);
            }
            this.onProductAssetModalDestroy();
          }
        });
    }


  }  