import {ExtTranslationKeys} from './ext-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */

export const ENExtTranslations: ExtTranslationKeys = {
    RGI_PORTAL_COMPONENTS: {
        INPUT_DOCUMENTS: 'INBOUND DOCUMENTS',
        ADD: 'Add',
        ADD_DOCUMENT: 'Add document',
        CANCEL: 'Cancel',
        CODE: 'Code',
        CONFIRM: 'Confirm',
        DELETE_DOC: 'Delete document',
        DOCUMENTS_MANAGER: 'Manage documents',
        DOCUMENT_TYPE: 'Document type',
        DOCUMENT_VALIDATION_MESSAGE: 'Validate document?',
        DOWNLOAD: 'Download',
        EDIT: 'Edit',
        FILE: 'File',
        LOAD: 'Load',
        NO: 'No',
        NOTE: 'Note',
        NO_FILES: 'No files',
        NUMBER_OF_FILES_INSERTED: '{{ filesLength }} of {{ fileMax }} files inserted',
        OUTPUT_DOCUMENTS: 'OUTBOUND DOCUMENTS',
        SAVE: 'Save',
        STATE: 'State',
        UPDATE_FILE: 'Update file',
        UPLOAD: 'UPLOAD',
        UPLOAD_NEW_DOCUMENT: 'Upload new document',
        VALIDATION: 'Validation',
        VALIDATION_DOCUMENT: 'Validation of documents',
        YES: 'Yes',
    },
    _ANAG_: {
        _MSG_: {
            _USER_ON_DIFFERENT_NODE_: 'Client not available. Use Genernet'
        }
    },
    EXT_COMPONENTS: {
        _CLAIMS_HISTORY_: {
            _LABEL_: {
                _INITIAL_BM_LEVEL_: 'Initial BM Level',
                _ACTUAL_BM_LEVEL_: 'Actual BM Level',
                _ORIGIN_: 'Origin',
                _HISTORY_MATRIX_: 'Claims matrix',
                _MTPL_: 'Mandatory Third Party Liability',
                _MOD_: 'Own damages',
                _YEARS_: 'Years',
                _CLAIMS_: 'Claims',
                _CLAIMS_PERC: '% Sinister',
                _COEFF_: 'Coefficient',
                _POLICY_NUMBER_: 'Policy number',
                _INCEPTION_DATE_: 'Inception date',
                _CANC_DATE_: 'Cancellation Date',
                _LICENSE_PLATE_: 'License plate',
                _VEHIC_TYPE_: 'Vehicle Type',
                _DATE_: 'Date',
                _MATERIAL_: 'Material damages',
                _BODILY_: 'Personal damages',
                _CALCULATION_DATE_: 'Calculation Date'
            }
        },
        _DEEP_LINK_: {
            _NOT_FOUND_: 'Not found',
            _CLOSE_: 'Close'
        }
    },
    _CLAIMS_: {
        _CAUSE: 'Cause'
    },
    RGI_SHARED_COMPONENTS:{
        ADD: 'Add',
        ADD_DOCUMENT: 'Add document',
        ARCHIVE: 'Archive',
        ARCHIVE_DOC: 'Archive document',
        CANCEL: 'Cancel',
        CLOSE: 'Close',
        CODE: 'Code',
        CONFIRM: 'Confirm',
        CREATED: 'Created',
        DELETE_DOC: 'Delete document',
        DELIVERY_DOC: 'Delivery document',
        DELIVERY_PARTY: 'Sending subject',
        DESCRIPTION: 'Description',
        DOWNLOAD_DOC: 'Download document',
        DOCUMENTS_MANAGER: 'Manage documents',
        DOCUMENT_TYPE: 'Document type',
        DOCUMENT_VALIDATION_MESSAGE: 'Validate document?',
        DOWNLOAD: 'Download',
        EDIT: 'Edit',
        FILE: 'File',
        INPUT_DOCUMENTS: 'INBOUND DOCUMENTS',
        LOAD: 'Load',
        NO: 'No',
        NOTE: 'Note',
        NO_FILES: 'No files',
        NUMBER_OF_FILES_INSERTED: '{{ filesLength }} of {{ fileMax }} files inserted',
        OUTBOUND_DOC_TITLE: 'Policy documents nr. {{ policyNumber }} of movement {{ movementDesc }}',
        OUTBOUND_DOC_PROPOSAL_TITLE: 'Proposal documents nr. {{ proposalNumber }} of movement {{ movementDesc }}',
        OUTPUT_DOCUMENTS: 'OUTBOUND DOCUMENTS',
        SAVE: 'Save',
        STATE: 'State',
        SIGN: 'Sign',
        SEND: 'Send',
        TYPE: 'Type',
        TO_BE_GENERATED: 'To be generated',
        UPDATE_FILE: 'Update file',
        UPLOAD: 'UPLOAD',
        UPLOAD_NEW_DOCUMENT: 'Upload new document',
        UPLOADED: 'Uploaded',
        UPLOADING_USER: 'User loading',
        VALIDATION: 'Validation',
        VALIDATION_DOCUMENT: 'Validation of documents',
        YES: 'Yes',
      },

      _MIC_:{
        _VEHICLE_ORIGIN_: 'Claim history'
      },
      RGI_RX: {
        DRAG_DROP: {
            SELECT: 'SELECT'
        }
    }
}
